.servicosText{
    background-color: #fee95a;
    border-radius: 10px;
    color: black;
    border: 5px solid #fee95a;
    align-items: center;
    width: 60vh;
    height: auto;
    justify-content: flex-start;
    font-weight: bold;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    text-decoration: none;
    font-family: "Gill Sans Extrabold", Helvetica, sans-serif;
    
    
  }
 
.servicosText:hover {background-color: black; color: #fee95a;
  }

.servicosText:active {
  background-color: black;
  color:white;
}
@media only screen and (max-width: 600px) {
  .servicosText{
    background-color: #fee95a;
    border: 5px solid #fee95a;
    color: black;
    width: auto;
    height: auto;
    transform: skew(0deg);
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    text-align: left;
    text-decoration: none;
    font-size: 14px;
    font-family: "Gill Sans Extrabold", Helvetica, sans-serif;
  }
  .servicosText:hover {background-color: black; color: #fee95a}
  
  .servicosText:active {
    background-color: black;
    color:white;
  border-color: white;
}
  
}
