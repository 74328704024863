.NavBar-NavBarCSS{
    background-color: black;
}
.navbar{
    padding: 0;
    padding-left: 4%;
}

@media only screen and (min-width: 576px) {
    .mr-sm-2{
        margin-right: 0 !important;
        
    }
}
.backgroundNav{
    background-color: white;
}
@media only screen and (max-width: 600px) {
    .NavBar-NavBarCSS{
        flex-flow: column;
        
    }
    .navbar{
        padding: 0.5rem 1rem;
    }
    .NavBar-ButtonContent{
        margin-top: 20px;
        flex-wrap:nowrap;

    }
  }
