.CarouselHome{
  height: auto;
}
.textCSS{
    text-decoration-color: black;
    color:black
}

.i2{
  color:white;
  font-size: 30px;
}
strong{
  font-size: 40px;
  -webkit-text-stroke-width: 1px;
-webkit-text-stroke-color: #000;
}

.rowparceiros{
  display: flex;
  margin-right: 0px;
  background-color: black;
  height: auto;
  color:white;
  margin-left: 0px;
}
.columnparceiros {
  background-color:white;
  display:flex;
  align-items: center;
  text-align: justify;
  justify-content: center;
  width: 50%;
  height: auto;
  font-family: 'Rajdhani', sans-serif;
  }
  .columnparceiros2 {
    background-color: white;
    display:flex;
    align-items: center;
    text-align: justify;
    justify-content: center;
    width: 50%;
    height: auto;
    font-family: 'Rajdhani', sans-serif;
    }
.rowparceiros:after {
    content: "";
    display: table;
    clear: both;
    margin-right: 0px;
    margin-left: 0px;
   }

h2{
    position: relative;
    color:black;
    font-size: 2rem;
    line-height: 0.9;
    text-align:justify;
}
.bgBlue{
    background-color: lightblue;
}
.carouselFluxo{
    background-color: whitesmoke;
    height: 800px;
    width: 700px;
    flex-flow:column;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Stencil Std, fantasy;
    font-style: italic;
    color: black;
}

@media only screen and (max-width: 600px) {
    .carouselFluxo{
        height: auto;
        width: auto;
        flex-flow:column;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Stencil Std, fantasy;
        font-style: italic;
        color:black;
  }
    h1{
        font-family: "Helvetica, sans-serif";
        font-size:30px;
    }
    .rowparceiros{
      display: flex;
      flex-flow: column wrap;
      margin-right: 0px;
      margin-left: 0px;
  }
    .rowparceiros:after {
      content: "";
      display: block;
      flex-flow: column wrap;
      clear: both;
      margin-right: 0px;
      margin-left: 0px;
    }
    .columnparceiros {
      width: 100%;
      height: 40vh;
      flex-flow: column wrap;
      font-family: 'Rajdhani', sans-serif;
  }
        .columnparceiros2 {
      width: 100%;
      height: 40vh;
      flex-flow: column wrap;
      font-family: 'Rajdhani', sans-serif;
}
 }

.servicosCSS{
    background-color: white;
    height: auto;
    max-width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Gill Sans Extrabold", Helvetica, sans-serif;

}
.img-responsive {
  display: block;
  max-width: 100%;
  max-height: auto;
}
.carousel-control-next{
  width:auto;
  
}
.carousel-control-prev{
  width:auto;
  
}
@media only screen and (max-width: 600px) {
.servicosCSS{
    background-color: white;
    height: auto;
    width: 100%;
    flex-flow: column;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Gill Sans Extrabold", Helvetica, sans-serif;
    
}
}


.espaco{
    height:6vh;
    display: flex;
    align-items: center;
    border: none;
    justify-content: center;
}
.espacoblack{
  height:6vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.espaco3{
  height:10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fee95a;
  color: white;
  -webkit-text-stroke-width: 1px;
-webkit-text-stroke-color: #000;
}
@media only screen and (max-width: 600px) {
    .espaco{
        height:3vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    h1{
        font-family: "Helvetica, sans-serif";
        font-size:30px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .espaco3{
      height: 6vh;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fee95a;
      color: white;
    }
    .CarouselHome{
      height: auto;
    }
    strong{
      font-size:30px;
      -webkit-text-stroke-width: 1px;
-webkit-text-stroke-color: #000;
    }
}


.carouselSize{
    background-color: whitesmoke;
    width: 100%;
    flex-flow:column;
    flex-wrap: nowrap;
    text-align:center;
    display: flex;
    align-items: center;
    justify-content: center;


}


::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar
{
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  background-color: #fee95a;
}

img {
    display: block;
    max-width: 100%;
  }
  .SliderCSS{
    width: auto;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .wrapper {
    overflow: hidden;
    height: 55vh;
    width: 100vh;
    display: grid;
    grid-template-rows: 2fr 1fr;
    grid-gap: 10px;
    border: solid 2px;
    border-color: whitesmoke;
}
.gallery {
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  display: flex;
 }
  
  .gallery__img {
    scroll-snap-align: start;
    margin-bottom: 10px;
    height: 55vh;
    width: 80vh;
    object-fit: cover;
  }
  .lil-nav {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    grid-row-start: 2;
  }
  .lil-nav a {
    height: 200px;
    display: flex;
    margin-bottom: 10px;
  }
  
  .lil-nav__img {
    object-fit: cover;
    filter: saturate(0);
    transition: 0.3s ease all;
  }
  
  .lil-nav__img:hover {
    transform: scale(1.05);
    filter: saturate(1);
  }




@media only screen and (min-width: 1200px) {
  .wrapper {
    grid-template-columns: 1fr 5fr;
    grid-template-rows: auto;
}
}

  @media screen and (min-width: 1200px) {
    .lil-nav { 
      overflow-y: scroll;
      overflow-x: hidden;
      display: block;
      grid-row-start: auto;
    }
    .lil-nav a {
      height: 200px;
      display: flex;
      margin-bottom: 10px;
    }
    
    .lil-nav__img {
      object-fit: cover;
      filter: saturate(0);
      transition: 0.3s ease all;
    }
    
    .lil-nav__img:hover {
      transform: scale(1.05);
      filter: saturate(1);
    }
  }

  @media screen and (min-width: 1200px) {
    .gallery { display: block;
      overflow-y: scroll;
      overflow-x: hidden;
      scroll-snap-type: y mandatory;
  }
}
.iLquPE{
  color:#fee95a;
  background-color: #fee95a;
}
.lgksPe{
  color:#fee95a;
  background-color: #fee95a;
}