.imgpad{
  padding-right: 5vh;
  justify-content: center;
  align-items: center;
}
.imgpad2{
  padding-right: 5vh;
  padding-left: 5vh;
  
}
.coaText{
    background-color: #fee95a;
    border: none;
    color: black;
    width: auto;
    display:flex;
    flex-wrap: wrap;
    flex-flow: column;
    height: auto;
    text-align: justify;
    text-decoration: none;
    font-size: 18px;
    font-family: "Gill Sans Extrabold", Helvetica, sans-serif;
  }
  
.coaText:hover {background-color: black; color:white; text-decoration: none;}

.coaText:active {
  background-color: black;
  color:white;
  border-color: white;
  text-decoration: none;
  box-shadow: 0 5px #ffffff;
}
.TextCenter{
  display:flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.TextDecoration{
  text-decoration: none!important;
}
@media only screen and (max-width: 600px) {
  .coaText{
    background-color: #fee95a;
    border: none;
    color: white;
    width: 100%;
    display: flex;
    height: auto;
    transform: skew(0deg);
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-family: "Gill Sans Extrabold", Helvetica, sans-serif;
  }
  .coaText:hover {background-color: #fee95a}
  
  .coaText:active {
    background-color: #fee95a;
  border-color: white;
  box-shadow: 0 5px #ffffff;
}
.imgpad{
  padding-right: 0vh;
}
.TextCenter{
  width: 100%;
  display:flex;
  flex-wrap: wrap;
  flex-flow: column;
  align-items: center;
  justify-content: center;

}
  h4{
    font-family: "Gill Sans Extrabold", Helvetica, sans-serif;
    color: white;
    font-size: 14px;


  }
}
