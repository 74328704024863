.backgimage{
    height: 100px;
}
h4{
    font-family: 'Rajdhani', sans-serif;
}
li{
    font-family: 'Trebuchet MS', sans-serif;
}
.strong2{
    font-family: 'Rajdhani', sans-serif;
    font-size: 26px;
    color:#F9E768;
}
.fileira{
    background: none;
    font-family: 'Rajdhani', sans-serif;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    background: black;
}
.coluna{
    background: none;
    width: 33%;
    text-align:center;
    justify-content: center;
    background: black;
    color: white;
}
.col-sm{
    font-size: 12px;
    color: white;
}
.fileira2{
    color: white;
    font-size: 12px;
    height: auto;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: black;
    
    
}
@media only screen and (max-width: 600px) {
    .fileira{
        font-family: 'Rajdhani', sans-serif;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }
    .coluna{
        width: 100%;
        text-align:center;
        justify-content: center;
    
    }
    .col-sm{
        width: 100%;
        text-align:center;
        justify-content: center;
    
    }
    .fileira2{
        display: flex;
        text-align: center;
        justify-content: center;
    }
}

.FaWhatsappCSS{
    color: white;
    width: 35px;
    height: 35px;
}

.TiSocialInstagramCSS{
    color: white;
    width: 35px;
    height: 35px;

}
.TiSocialLinkedinCSS{
    color: white;
    width: 35px;
    height: 35px;

}