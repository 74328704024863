.ButtonC{
    background-color: #F9E768;
    border: none;
    color: black;
    width: auto;
    height: 7vh;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;

    }
    
  .ButtonC:hover {background-color: black; color: white}
  
  .ButtonC:active {
    background-color: black;
    color: white;
    border-color: white;
  }


  @media only screen and (max-width: 600px) {
    .ButtonC{
      background-color: #F9E768;
      border: none;
      color: black;
      padding: 10px 5px;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: none;
      text-decoration: none;
      font-size: 15px;
      font-family: "Gill Sans Extrabold", Helvetica, sans-serif;
    }
    .ButtonC:hover {background-color: black; color: white}
    
    .ButtonC:active {
      background-color: black;
      border-color: white;
  }
  .ButtonsItens2{
    background-color: BLACK;
    height:auto;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 20px;

  }
}