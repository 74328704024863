.ContatoPageCss{
    background-color:black;
    height:90vh;
    border-color: black;
    flex-flow: column;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family:  "DM Sans","Arial","Helvetica Neue","Helvetica", sans-serif;

}


.BlackBorder{
    height: 4px;
    color: black;
    background-color: black;
    padding: auto;
    margin: auto;
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
h3{
    font-weight: bold;
    font-size: 21px;
}

.ContatoPageCss2{
    background-color:black;
    height:85vh;
    flex-flow: column;
    flex-wrap: nowrap;
    display: end;
    text-align: end;
    justify-content: right;
    align-items: flex-end;

}
.ContatoText1{
    background-color: #f9e768;
    height:8vh;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 32px;
}

.mapaCss{
    width: 600px;
    height: 500px;
    display: end;
    text-align: center;
    justify-content: right;
    align-items: flex-end;
    border: #fee95a;
    border-style: solid;
    border-width: 5px;
}
.rowContato{
    display: flex;
    
}
.rowContato:after {
    content: "";
    display: table;
    clear: both;
  }

.columnContato {
    display:flex;
    flex-flow:column;
    align-items: flex-start;
    text-align: justify;
    justify-content: center;
    padding-left: 15vh;
    width: 50%;
    height: auto;
    color: white;
    background-color:black;
    font-weight: bold;
}
  .column2Contato {
    display:flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: auto;
    background-color:black;
    
  }
  .backGBlack{
      background-color:black;
  }
 
@media only screen and (max-width: 600px) {
    .ContatoPageCss{
        background-color:black;
        height:75vh;
        flex-flow: column;
        flex-wrap: nowrap;
        padding-right: 5vh;
        padding-left: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
    
    }
        
     h3{
            font-size: 18px;
            font-weight: bold;
    }
    .mapaCss{
        width: 30vh;
        height: 40vh;
        display: flex;
        flex-flow: column;
        flex-wrap: nowrap;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .rowContato{
        display: grid;
    }
    .rowContato:after {
        content: "";
        display: table;
        clear: both;
      }
    
    .columnContato {
        display:flex;
        flex-flow:column;
        align-items: flex-start;
        text-align: justify;
        justify-content: center;
        padding-left: 0vh;
        width: 100%;
        height: auto;
        background-color:black;
        font-family: "Helvetica, sans-serif";
      }
      .column2Contato {
        display:flex;
        flex-flow:column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        background-color:black;
        
      }
}