.bgcolor1{
  background-color: white;
  width: 100%;
}

.ServicosPageCss{
    background-color: white;
    height:auto;
    display: flex;
    align-items: left;
    justify-content: left;
    font-family: "Helvetica, sans-serif";
    font-size: 22px;
}
.EmpresaText1{
  width:100%;

}
.ServicosText1{
  background-color: #f9e768;
    height:8vh;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 32px;
}

.espaco2{
    width: 100%;
}
h2{
  font-size: 30px;
  color:#fee95a;
}
.h22{
  font-size: 30px;
  color:white;
}

.row{
  display: flex;
  margin-right: 0px;
  background-color: black;
  color:white;
  margin-left: 0px;
}
.rowEMPRESA{
  display: flex;
  margin-right: 0px;
  background-color: black;
  color:white;
  margin-left: 0px;
}
.espacob2{
  height: 3vh;
  background-color: black;
}
.espacob3{
  height: 3vh;
  background-color: white;
}
.apresentacao{
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: black;
  color:white;

}
.h52{
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 30px;

}
.i2{
  color:#fee95a;
  font-size: 50px;
  font-family: 'Trebuchet MS', sans-serif;
}
.row:after {
    content: "";
    display: table;
    clear: both;
    margin-right: 0px;
    margin-left: 0px;
  }
  .row3{
    display: flex;
    margin-right: 0px;
    margin-left: 0px;
  }
  .row3:after {
      content: "";
      display: table;
      clear: both;
      margin-right: 0px;
      margin-left: 0px;
    }

.column {
  display:flex;
  align-items: center;
  text-align: justify;
  justify-content: center;
  width: 55%;
  padding-left: 10vh;
  height: 65vh;
  font-family: 'Rajdhani', sans-serif;
  }
  .columnEMPRESA{
  display:flex;
  align-items: center;
  text-align: justify;
  justify-content: left;
  width: 55%;
  padding-left: 10vh;
  height: 40vh;
  font-family: 'Rajdhani', sans-serif;

  }
  .e1column {
    display:flex;
    align-items: center;
    text-align: justify;
    justify-content: center;
    padding-left:10vh;
    padding-right: 10vh;
    width: 100%;
    height: auto;
    font-family: 'Rajdhani', sans-serif;
    }
    .e2column {

      display:flex;
      align-items: left;
      text-align: justify;
      justify-content: left;
      padding-left:10vh;
      width: 100%;
      height: auto;
      font-family: 'Rajdhani', sans-serif;
      }
  .column2 {
    display:flex;
    align-items: center;
    text-align: justify;
    justify-content: center;
    width: 45%;
    padding-left: 5vh;
    padding-right: 10vh;
    height: 65vh;
    font-family: 'Rajdhani', sans-serif;
    
  }
  .column2EMPRESA{
    display:flex;
    align-items: center;
    text-align: justify;
    justify-content: center;
    width: 45%;
    padding-left: 5vh;
    padding-right: 10vh;
    height: 45vh;
    font-family: 'Rajdhani', sans-serif;
    
  }
  .ecolumn {
    display:flex;
    align-items: center;
    text-align: justify;
    justify-content: center;
    width: 55%;
    padding-left: 10vh;
    height: auto;
    font-family: 'Rajdhani', sans-serif;

    }
    .ecolumn2 {
      
      display:flex;
      flex-flow: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      width: 45%;
      padding-left: 5vh;
      padding-right: 10vh;
      height: auto;
      font-family: 'Rajdhani', sans-serif;
  
      
    }

  .row2{
    display: flex;
    margin-right: 0px;
    margin-left: 0px;
}
.row2:after {
    content: "";
    display: table;
    clear: both;
    margin-right: 0px;
    margin-left: 0px;
  }
  .column3 {
    display:flex;
    align-items: center;
    text-align: justify;
    justify-content: center;
    width: 45%;
    background-color: white;
    padding-left: 10vh;
    height: 65vh;
    font-family: 'Rajdhani', sans-serif;
  }
  .column4 {
    display:flex;
    align-items: center;
    text-align: justify;
    justify-content: center;
    width: 55%;
    padding-left: 5vh;
    padding-right: 10vh;
    border-radius: 10px;
    height: 65vh;
    background-color: white;
    font-family: 'Trebuchet MS', sans-serif;
    }

  h5{
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 23px;
    font-style: italic;
  }
  
  i{
    color:#fee95a;
    font-size: 40px;
    font-family: 'Trebuchet MS', sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
  }
  h6{
    color:#fee95a;
    font-size: 40px;
    font-family: 'Trebuchet MS', sans-serif;
    
  }
 
  .i3{
    color:white;
    font-size: 30px;
    font-family: 'Trebuchet MS', sans-serif;
  }


  @media only screen and (max-width: 600px) {
    .apresentacao{
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: black;
      color:white;
    
    }
    .h52{
      font-family: 'Trebuchet MS', sans-serif;
      font-size: 20px;
    
    }
    .i2{
      color:#fee95a;
      font-size: 30px;
      font-family: 'Trebuchet MS', sans-serif;
    }
    .row{
        display: flex;
        flex-flow: column wrap;
        margin-right: 0px;
        margin-left: 0px;
    }
    .row:after {
        content: "";
        display: block;
        clear: both;
        margin-right: 0px;
        margin-left: 0px;
      }
      .row3{
        display: flex;
        flex-flow: column wrap;
        margin-right: 0px;
        margin-left: 0px;
      }
      .row3:after {
        content: "";
        display: block;
        clear: both;
        margin-right: 0px;
        margin-left: 0px;
        }
    .column {
        padding-left: 2vh;
        padding-right: 2vh;
        width: 100%;
        height: 50vh;
        font-family: 'Rajdhani', sans-serif;
    }
    .columnEMPRESA {
      padding-left: 2vh;
      padding-right: 2vh;
      width: 100%;
      height: 35vh;
      font-family: 'Rajdhani', sans-serif;
  }
    
    
    .column2 {
        padding-right: 0vh;
        padding-left: 0vh;
        width: 100%;
        height: 50vh;
        font-family: 'Rajdhani', sans-serif;
    }
    .column2EMPRESA {
      padding-right: 0vh;
      padding-left: 0vh;
      width: 100%;
      height: 35vh;
      font-family: 'Rajdhani', sans-serif;
  }
    .ecolumn {
      padding-left: 2vh;
      padding-right: 2vh;
      width: 100%;
      height: auto;
      font-family: 'Rajdhani', sans-serif;
  }
  .ecolumn2 {
      padding-right: 0vh;
      padding-left: 0vh;
      width: 100%;
      height: auto;
      font-family: 'Rajdhani', sans-serif;
  }
  .e1column {
    padding-left: 2vh;
    padding-right: 2vh;
    width: 100%;
    height: auto;
    font-family: 'Rajdhani', sans-serif;
}
.e2column {
  padding-left: 2vh;
  padding-right: 2vh;
  width: 100%;
  height: auto;
  font-family: 'Rajdhani', sans-serif;
}

    .column3 {
        padding-left: 0vh;
        padding-right: 0vh; 
        width: 100%;
        height: 50vh;
        font-family: 'Rajdhani', sans-serif;
    }
    .column4 {
        padding-left: 2vh;
        padding-right: 2vh;
        width: 100%;
        height: 50vh;
        font-family: 'Rajdhani', sans-serif;
    }
    h5{
      font-family: 'Trebuchet MS', sans-serif;
        font-size: 16px;
        font-style: italic;
    
      }
      i{
        color:#fee95a;
        font-size: 26px;
        font-family: 'Trebuchet MS', sans-serif;
      }
      h6{
        color:#fee95a;
        font-size: 26px;
        font-family: 'Trebuchet MS', sans-serif;
        font-style:italic
      }

      .row2{
        display: flex;
        flex-flow: column wrap;
        margin-right: 0px;
        margin-left: 0px;
    }
    .row2:after {
        content: "";
        display: block;
        clear: both;
        margin-right: 0px;
        margin-left: 0px;
      }
    .imgr{
        display: block;
        max-width: 100%;
        height: auto;
    }
  
}