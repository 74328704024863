.ClientesButton{
    background-color: white;
    border: none;
    color: white;
    width: auto;
    max-width: auto;
    height: auto;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-family: "Gill Sans Extrabold", Helvetica, sans-serif;
  }

@media only screen and (max-width: 600px) {
  .ClientesButton{
    background-color: white;
    border: none;
    color: white;
    width: auto;
    height: auto;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-family: "Gill Sans Extrabold", Helvetica, sans-serif;
  }
 
}