.footer {
    max-height: auto;
    width: 100%;
    color: white;
    font-size: 14px;
    text-align: center;
    justify-items: center;
    font-family: 'Trebuchet MS', sans-serif; 
  }
  .linha{
    display: flex;
    flex-flow: row wrap;
    height: auto;
    border-top: solid 8px;
    border-bottom: solid 8px;
    border-color: #fee95a;
    background-color: black;
    color: white;
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 18px;

  }
  .coluna-25{
    width: 33%;
    text-align: center;
    justify-content: center;
    font-family: 'Trebuchet MS', sans-serif;
  }
  .a4{
    color: white;
  }
  .a4:hover { color: #fee95a;
  }

  .a4:active{
    color:#fee95a;
  }
  .icone3{
    height: 50px;
    width: 75px;
    color: #fee95a;

  }
  @media only screen and (max-width: 600px) {
    .linha{
   
      display: flex;
      flex-flow: row wrap;
      height: auto;
      border-top: solid 8px;
      border-bottom: solid 8px;
      border-color: #fee95a;
      background-color: black;
      color: white;
      font-family: 'Trebuchet MS', sans-serif;
      font-size: 15px;
  
    }

  }
    