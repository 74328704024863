.CatalogoPageCss{
    background-color: black;
    height:auto;
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 70%;
    border: solid 5px;
    border-color: #f9e768;
    margin-right: auto;
    margin-left: auto;
 
}
.CatalogoText1{
    background-color: #f9e768;
    height:8vh;
    color: white;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 32px;
}

hr{
        border: 6px inset #f9e768;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: -2px;
        
}
.hr2{
    border: 6px inset #f9e768;
        width: auto;
        margin-left: auto;
        margin-right: auto;
  }

.ButtonsItens{
    background: black;
    height:auto;
    color: white;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 20px;

}

 .EspacoBotoes{
     height: 2vh;
 }
 .backGBlack{
     background: black;
 }

 @media only screen and (max-width: 600px) {
    .ButtonsItens{
        background-color: black;
        height:auto;
        color: white;
        display: flex;
        width: auto;
        align-items: center;
        justify-content: center;
        font-size: 20px;
    
    }

    .CatalogoPageCss{
        background: black;
        height:auto;
        display: flex;
        flex-flow: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        width: 95%;
        border: solid 5px;
        border-color: #f9e768;
        margin-right: auto;
        margin-left: auto;
     
    }
    .ButtonsItens{
        background: black;
        height:auto;
        color: white;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 20px;
    
    }
 }