.ServicosButton{
  background-color: #F9E768;
  border: none;
  color: black;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
  }
  
.ServicosButton:hover {background-color: black; color: white;}

.ServicosButton:active {
  background-color: black;
  border-color: white;
  color: white;
 
}
@media only screen and (max-width: 600px) {
  .ServicosButton{
    background-color: #fee95a;
    border: none;
    color: black;
    margin-top: -50%;
    padding: 14px 10px;
    text-align: center;
    text-decoration: none;
    font-size: 17px;
    font-family: "Gill Sans Extrabold", Helvetica, sans-serif;
  }
  .ServicosButton:hover {background-color: black}
  
  .ServicosButton:active {
    background-color: black;
    color:white;
    border-color: white;
}
}